import './topbar.css';

import { BsInstagram } from "react-icons/bs";
import { ImWhatsapp } from "react-icons/im";
import { HiOutlineMail } from "react-icons/hi";

import logo from '../assets/movexlogobranco.png';



function Topbar() {
    return(
        <>
        <div className="globaltopbar">
            <img src={logo} alt="teste" className='logotopbar'/>
        </div>
        <div className='blackline'>
            
        </div>
        </>
    );
}

export default Topbar;