import React, { useState } from 'react';

import './LandingPage.css';
import Navbar from '../components/Navbar';
import Img1 from '../assets/img1.webp';
import BtnDefault1 from '../components/btn-default1';
import BtnDefault2 from '../components/btn-default2';
import FormsContact from '../components/forms-contact';
import SocialMedia from '../components/SocialMedia';


import Footer from '../components/footer'

import BtnDefault from '../components/btn-default';
import WrapperClients from '../components/wrapperclients';



function Company() {

    return(
        <>
         <div className="global">
            <Navbar />
            <div className='blackline'></div>

         

            <div className="clientes page unicroutecli">
        <div className='blackfaixa bfclie'>
            <h3>
                Nossos clientes
            </h3>
            <h2 className='text-title-section'>
                Marcas que fazem parte<br></br> da nossa história
            </h2>
            <div className='btn-clientes-fo'>
                <BtnDefault/>
            </div>
        </div>

        <div className='wrapperclients'>
          <WrapperClients />
        </div>
        
        <div className='clients-container'>
          <div className='clients-text'>
            <h3 className='contact-text'>
            Você também tem a oportunidade de se tornar um 
            cliente da nossa empresa e desfrutar de todos os 
            benefícios que oferecemos. Venha fazer parte da nossa 
            comunidade e transforme sua experiência conosco em uma 
            jornada eficaz, profissional e encantadora.
            </h3>
          </div>
          <div className='clients-btn'>
            <a 
                  href='https://api.whatsapp.com/send/?phone=5551999078668&text=Ol%C3%A1%21+Visitei+a+p%C3%A1gina+de+voc%C3%AAs+e+estou+interessado+em+or%C3%A7ar+um+projeto+para+meu+neg%C3%B3cio%21&type=phone_number&app_absent=0' 
                  alt="" target='_blank' rel="noreferrer"
                  >
              <BtnDefault1 />
              <BtnDefault2 />
            </a>
          </div>
        </div>
  
            
            </div>

            <div className="contato page">
            <div className='blackfaixa bfcont'>
              <h2 className='text-title-section'>
                Conte conosco para elevar<br></br>seu negócio para o<br></br>próximo nível
              </h2>
              <h3>
                Entre em contato com a gente
              </h3>
            </div>

            <div className='contact-container'>
              <div className='contact-text'>
                <h3>Nossa equipe de suporte está disponível para ajudar 
                  com qualquer problema que você possa ter, e faremos o 
                  nosso melhor para garantir que sua experiência com nossa 
                  empresa seja a melhor possível. Venha fazer parte da nossa 
                  comunidade e transforme sua experiência conosco em uma jornada 
                  eficaz, profissional e encantadora.</h3>
              </div>
              <div className='contact-form'>
                <FormsContact />
              </div>
            </div>

            <div className='orangeline'></div>
                <SocialMedia />
            </div>

            <Footer /> 
         </div>
        </>
    );
}

export default Company;



