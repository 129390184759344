import React, { useState } from 'react';
import './LandingPage.css';
import Navbar from '../components/Navbar';
import Gallery from '../components/gallery';
import FormsContact from '../components/forms-contact';
import SocialMedia from '../components/SocialMedia';
import Footer from '../components/footer'
import BtnDefault from '../components/btn-default';




function Portfolio() {
{/* 
    const [data, setData] = useState({img: '', i: 0})  

    const viewImage = (img, i)=>{
           setData({img, i})
    }

    const imgAction = (action) => {
        let i = data.i;
        if(action === 'next-img'){
            setData({img: images[i + 1], i: i + 1});
        }
        if(action === 'previous-img'){
            setData({img: images[i - 1], i: i - 1});
        }
        if(!action){
            setData({img: '', i: 0});
        }
    }
*/}
    return(
        <>
         <div className="global">
            <Navbar />
            <div className='blackline'></div>

            <div className="portfolio page unicrouteport">

                <div className='blackfaixa bfport'>
                    <h2 className='text-title-section'>
                        Portfolio
                    </h2>
                    <div className='btn-portfolio-fo'>
                        <BtnDefault/>
                    </div>
                </div>

                <div className='portfolio-container'>
                <Gallery />
                </div>
            
            </div>

            <div className="contato page">
            <div className='blackfaixa bfcont'>
              <h2 className='text-title-section'>
                Conte conosco para elevar<br></br>seu negócio para o<br></br>próximo nível
              </h2>
              <h3>
                Entre em contato com a gente
              </h3>
            </div>

            <div className='contact-container'>
              <div className='contact-text'>
                <h3>Nossa equipe de suporte está disponível para ajudar 
                  com qualquer problema que você possa ter, e faremos o 
                  nosso melhor para garantir que sua experiência com nossa 
                  empresa seja a melhor possível. Venha fazer parte da nossa 
                  comunidade e transforme sua experiência conosco em uma jornada 
                  eficaz, profissional e encantadora.</h3>
              </div>
              <div className='contact-form'>
                <FormsContact />
              </div>
            </div>

            <div className='orangeline'></div>
            <SocialMedia />
            </div>

            <Footer /> 
         </div>
        </>
    );
}

export default Portfolio;



