import React, { useState } from 'react';

import './LandingPage.css';
import Navbar from '../components/Navbar';
import Gallery from '../components/gallery';
import Img1 from '../assets/img1.webp';
import BtnDefault2 from '../components/btn-default2';
import BtnDefault3 from '../components/btn-default3';


import Footer from '../components/footer'

import BtnDefault from '../components/btn-default';
import SliderGallery from '../components/slidergallery';



function Company() {

    return(
        <>
         <div className="global">
            <Navbar />
            <div className='blackline'></div>

            <div className='company page unicroutecomp'>
        
            <div className='blackfaixa bfempr'>
                <h2 className='text-title-section'>
                    Empresa
                </h2>
                <div className='btn-empresa-fo'>
                    <BtnDefault/>
                </div>
            </div>

            <div className='company-container'>
              <div className='company-text'>
                <h3 className='contact-text'>
                    A MOVEX é uma empresa de consultoria com mais
                    de 40 anos de experiência no mercado varejista. 
                    Nossa equipe é composta por profissionais altamente 
                    qualificados e dedicados a oferecer a melhor solução para o seu negócio
                </h3>
              </div>
              <div className='company-img'>
                  <img src={Img1} alt="" className='company-img-div' /> 
              </div>
              <div className='company-text'>
                <h3 className='contact-text'>
                Com a Movex, você pode contar com serviços personalizados 
                para o seu negócio, ajudando-o a se destacar no mercado e 
                aumentar suas vendas. Se você deseja melhorar a aparência 
                e a experiência de compra dos seus clientes, conte com a Movex para lhe ajudar.
                </h3>
              </div>
              <div className='company-btns'>
                <a 
                      href='https://api.whatsapp.com/send/?phone=5551999078668&text=Ol%C3%A1%21+Visitei+a+p%C3%A1gina+de+voc%C3%AAs+e+estou+interessado+em+or%C3%A7ar+um+projeto+para+meu+neg%C3%B3cio%21&type=phone_number&app_absent=0' 
                      alt="" target='_blank' rel="noreferrer"
                      >
                  <BtnDefault3 />
                  <BtnDefault2 />
                </a>
              </div>
              
            </div>

            </div> 

            <div className="portfolio page">

                <div className='blackfaixa bfport'>
                    <h2 className='text-title-section'>
                        Portfolio
                    </h2>
                    <div className='btn-portfolio-fo'>
                        <BtnDefault/>
                    </div>
                </div>

                <div className='portfolio-container'>
                <Gallery />
                </div>
            
            </div>

            <Footer /> 
         </div>
        </>
    );
}

export default Company;



